<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component
          :is="Component"
          class="main tw-py-6 tw-px-4 lg:tw-px-8 xl:tw-px-"
        />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/normalize.scss";
@import "@/assets/scss/main.scss";
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
